import { RewardsExtension } from '/~/core/extension'
import { FlowType } from '/~/composables/checkout/checkout-types'

class PayrollExtension extends RewardsExtension {
  setRoutes() {
    return [
      {
        path: '/payments/payroll',
        component: () => import('/~/extensions/payroll/root.vue'),
        meta: {
          parentRoute: 'payments',
          flowType: FlowType.payroll,
        },
        children: [
          {
            path: '',
            name: 'payroll-upload',
            component: () => import('/~/extensions/payroll/views/upload.vue'),
            meta: {
              title: 'Pay your payroll',
              flowType: FlowType.payroll,
            },
          },
          {
            path: 'pending-payees',
            name: 'payroll-payment-pending-payees',
            component: () =>
              import('/~/extensions/payroll/views/pending-payees.vue'),
            meta: {
              title: 'Pending payees',
              flowType: FlowType.payroll,
            },
          },
          {
            path: 'unsupported-payees-detected',
            name: 'payroll-payment-unsupported-payees-detected',
            component: () =>
              import(
                '/~/extensions/payroll/views/unsupported-payees-detected.vue'
              ),
            meta: {
              title: 'Ineligible payees detected',
              flowType: FlowType.payroll,
            },
          },
        ],
      },
    ].filter(Boolean)
  }
}

export default PayrollExtension
